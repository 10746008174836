<!-- eslint-disable no-return-assign -->
<template>
  <div class="journalList box" ref="target">
    <div class="header">
      <div class="journalFilter">
        <h2>Filtre</h2>
        <va-button
          icon="cached"
          id="filterRefresh"
          class="refreshButton"
          size="small"
          @click="getRequests()"
        />
        <va-button
          icon="clear"
          id="filterClear"
          size="small"
          class="clearFilterButton"
          @click="clearFilter()"
        />
        <div class="journalFilterGrid">
          <va-select
            label="Compte"
            v-model="customFilter.compte"
            clearable
            autocomplete
            :options="compteSelect"
            ref="filterAccountSelect"
            placeholder="Tous les comptes"
            :text-by="(cpt: Compte) => cpt.id + ' - ' + cpt.libelle"
            :track-by="(cpt: Compte) => String(cpt.id)"
          />
          <va-date-input
            class="mb-4"
            label="De"
            v-model="customFilter.from"
            first-weekday="Monday"
            manual-input
            highlight-weekend
            :allowed-days="(date:Date) => dateInEcritureDateRange(date)"
            :allowed-months="(date:Date) => dateInEcritureDateRange(date)"
            :allowed-years="(date:Date) => dateInEcritureDateRange(date)"
          />
          <va-date-input
            class="mb-4"
            label="À"
            v-model="customFilter.to"
            first-weekday="Monday"
            manual-input
            highlight-weekend
            :allowed-days="(date:Date) => dateInEcritureDateRange(date)"
            :allowed-months="(date:Date) => dateInEcritureDateRange(date)"
            :allowed-years="(date:Date) => dateInEcritureDateRange(date)"
          />
          <va-input
            label="libelle"
            class="mb-4"
            v-model="customFilter.libelle"
            type="search"
            clearable
          >
            <template #prependInner>
              <va-icon name="search" />
            </template>
          </va-input>
          <div
            class="filterSum"
            v-if="!(filterSum === null || filterSum === undefined)">
            Somme du compte:
            <va-chip color="success">
              {{ monetaryFormat.format(filterSum) }}
            </va-chip>
          </div>
        </div>
      </div>
    </div>
    <va-data-table
      striped
      hoverable
      footer-clone
      allow-footer-sorting
      animated
      :loading="loading"
      :columns="columns"
      :items="ecritures"
      sticky-header
      class="journalTable content"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :row-bind="getEditedRowBind"
      @filtered="filtered = $event.items"
    >
      <template #cell(ID)="{ source: ID }">
        <va-button
          icon="visibility"
          preset="secondary"
          flat
          :color="hasFichier(ID) ? '#e5b50a' : 'primary'"
          @click="showEcriture(ID)" />
        <va-button
          icon="edit"
          preset="secondary"
          :disabled="!isUserAdmin"
          @click="editEcriture(ID)" />
        <va-button
          icon="delete"
          preset="secondary"
          :disabled="!isUserAdmin"
          @click="removeEcriture(ID)"
          v-if="lastID === Number(ID)" />
        {{ ID }}
      </template>

      <template #cell(montant)="{ source: montant }">
        {{ monetaryFormat.format(montant) }}
      </template>
      <template #cell(date)="{ source: date }">
        {{ date.toISOString().split('T')[0] }}
      </template>
      <template #cell(debit)="{ value }">
        <strong
          v-if="Number(customFilter.compte.id) === Number(value)">
          {{ value }}
        </strong>
        <span
          class="journalCell"
          v-else>
          {{ value }}
        </span>
      </template>
      <template #cell(credit)="{ value }">
        <strong
          v-if="Number(customFilter.compte.id) === Number(value)">
          {{ value }}
        </strong>
        <span
          class="journalCell"
          v-else>
          {{ value }}
        </span>
      </template>
      <template #cell(libelle)="{ value }">
        <div
          v-if="customFilter.libelle.length > 0"
          v-html="highlightText(value, customFilter.libelle)" />
        <div
          v-else>
          {{ value }}
        </div>
      </template>
    </va-data-table>
    <div class="footer">
      <div
        v-if="showEcritureForm"
        :class="editMode ? 'jounalFormEdit' : 'journalForm'"
      >
        <va-form
          id="ecritureForm"
          tag="form"
          ref="formEcritureTVA"
          immediate
          @submit.prevent="addEcritureTVA"
          v-if="comptabilite.TVA === '1'"
        >
          <div class="journalGrid">
            <va-date-input
              class="mb-4"
              label="date"
              v-model="form.ecriture.date"
              first-weekday="Monday"
              manual-input
              highlight-weekend
              :allowed-days="(date:Date) => dateInExerciceComptableRange(date, exerciceComptable)"
              :allowed-months="(date:Date) => dateInExerciceComptableRange(date, exerciceComptable)"
              :allowed-years="(date:Date) => dateInExerciceComptableRange(date, exerciceComptable)"
              :rules="[(date:Date) => dateInExerciceComptableRange(date, exerciceComptable)]"
            />
            <va-select
              label="debit"
              class="mb-4"
              v-model="form.ecriture.debit"
              autocomplete
              :options="compteSelect"
              :text-by="(cpt: Compte) => cpt.id + ' - ' + cpt.libelle"
              :track-by="(cpt: Compte) => String(cpt.id)"
              :rules="[(cpt:Compte) => cpt || 'Champ requis']"
            />
            <va-select
              label="credit"
              class="mb-4"
              v-model="form.ecriture.credit"
              searchable
              :options="compteSelect"
              :text-by="(cpt: Compte) => cpt.id + ' - ' + cpt.libelle"
              :track-by="(cpt: Compte) => String(cpt.id)"
              :rules="[(cpt:Compte) => cpt || 'Champ requis']"
            />
            <va-input
              label="libelle"
              class="mb-4"
              v-model="form.ecriture.libelle"
              :rules="[(value:string) => (value && value.length > 0) || 'Champ Requis']"
            />
            <div class="journalGridTVA">
              <VaRadio
                v-model="TVA.toggle"
                :options="TVA.toggleValue"
                value-by="value"
              />
            </div>
            <va-input
              label="montant Avec TVA"
              class="mb-4"
              v-model="form.ecriture.montant"
              @change="TVAChange()"
              mask="numeral"
              :rules="[(value:string) => (value && value.length > 0) || 'Champ Requis']"
            />
            <va-input
              label="montantTVA"
              class="mb-4"
              v-model="form.montantTVA"
              @change="TVAChange()"
              mask="numeral"
              disabled
            />
            <va-input
              label="montant hors TVA"
              class="mb-4"
              v-model="form.montantHorsTVA"
              @change="TVAChange()"
              mask="numeral"
              disabled
            />
            <div class="journalGridTVA">
              <va-file-upload
                v-model="pendingFile"
                class="mb-4"
                type="single"
                file-types="image/*,.pdf"
                :color="editMode && form.ecriture.fichier !== undefined ? 'Warning' : 'Primary'"
                :upload-button-text="editMode && form.ecriture.fichier !== undefined ? 'Changer le fichier ' : 'Choisir un fichier'"
                undo-button-text="Annuler"
                deleted-file-message="Supprimer"
                :disabled="modalFileSelected.id !== undefined"
              />
            </div>
            <va-button
              @click="showChoosingFileModal = true"
              class="mb-4"
              :disabled="pendingFile !== undefined"
              v-if="pendingFile === undefined"
            >
              Lié à un fichier existant
            </va-button>
            <va-select
              label="Type Fichier"
              class="mb-4"
              :options="typesFilesOptions"
              v-model="pendingFileType"
              :text-by="(tfi: TypeFichier) => tfi.libelle"
              :track-by="(tfi: TypeFichier) => String(tfi.id)"
              :rules="[(tfi:TypeFichier) => tfi || 'Champ requis']"
              searchable
              v-else
            />
            <va-button
              preset="secondary"
              class="mr-6 mb-2"
              round
              icon="close"
              size="small"
              @click="modalFileSelected = {} as Fichier"
              v-if="modalFileSelected.id !== undefined"
            >
              {{ modalFileSelected.nom }}
            </va-button>
          </div>
          <va-button type="submit" class="mb-4" color="success">
            Valider
          </va-button>
        </va-form>
        <va-form
          id="ecritureForm"
          tag="form"
          ref="formEcritureSansTVA"
          immediate
          @submit.prevent="addEcriture"
          v-else
        >
          <div class="journalGrid">
            <va-date-input
              class="mb-4"
              label="date"
              v-model="form.ecriture.date"
              first-weekday="Monday"
              manual-input
              highlight-weekend
              :allowed-days="(date:Date) => dateInExerciceComptableRange(date, exerciceComptable)"
              :allowed-months="(date:Date) => dateInExerciceComptableRange(date, exerciceComptable)"
              :allowed-years="(date:Date) => dateInExerciceComptableRange(date, exerciceComptable)"
              :rules="[(date:Date) => dateInExerciceComptableRange(date, exerciceComptable)]"
            />
            <va-select
              label="debit"
              class="mb-4"
              v-model="form.ecriture.debit"
              searchable
              :options="compteSelect"
              :text-by="(cpt: Compte) => cpt.id + ' - ' + cpt.libelle"
              :track-by="(cpt: Compte) => String(cpt.id)"
              :rules="[(cpt:Compte) => cpt || 'Champ requis']"
            />
            <va-select
              label="credit"
              class="mb-4"
              v-model="form.ecriture.credit"
              searchable
              :options="compteSelect"
              :text-by="(cpt: Compte) => cpt.id + ' - ' + cpt.libelle"
              :track-by="(cpt: Compte) => String(cpt.id)"
              :rules="[(cpt:Compte) => cpt || 'Champ requis']"
            />
            <va-input
              label="libelle"
              class="mb-4"
              v-model="form.ecriture.libelle"
              :rules="[value => (value && value.length > 0) || 'Field is required']"
            />
            <va-input
              label="montant"
              class="mb-4"
              v-model="form.ecriture.montant"
              mask="numeral"
              :rules="[(value:string) => (value && value.length > 0) || 'Champ Requis']"
            />
            <va-file-upload
              v-model="pendingFile"
              class="mb-4"
              type="single"
              file-types="image/*,.pdf"
              :color="editMode && form.ecriture.fichier !== undefined ? 'Warning' : 'Primary'"
              :upload-button-text="editMode && form.ecriture.fichier !== undefined ? 'Changer le fichier ' : 'Choisir un fichier'"
              undo-button-text="Annuler"
              deleted-file-message="Supprimer"
              :disabled="modalFileSelected.id !== undefined"
            />
            <va-button
              @click="showChoosingFileModal = true"
              class="mb-4"
              :disabled="pendingFile !== undefined"
              v-if="pendingFile === undefined"
            >
              Lié à un fichier existant
            </va-button>
            <va-select
              label="Type Fichier"
              class="mb-4"
              :options="typesFilesOptions"
              v-model="pendingFileType"
              :text-by="(tfi: TypeFichier) => tfi.libelle"
              :track-by="(tfi: TypeFichier) => String(tfi.id)"
              :rules="[(tfi:TypeFichier) => tfi || 'Champ requis']"
              searchable
              v-else
            />
            <va-button
              preset="secondary"
              class="mr-6 mb-2"
              round
              icon="close"
              size="small"
              @click="modalFileSelected = {} as Fichier"
              v-if="modalFileSelected.id !== undefined"
            >
              {{ modalFileSelected.nom }}
            </va-button>
            <va-button type="submit" class="mb-4" color="success">
              Valider
            </va-button>
          </div>
        </va-form>
      </div>
      <va-button
        v-if="showEcritureForm"
        class="journalButton"
        icon="cancel"
        color="danger"
        @click="hideEcritureForm()"
      >
        Annuler
      </va-button>
      <div v-else class="journalButton">
        <va-button
          icon="add"
          @click="addEcritureForm()"
        >
          Ajouter
        </va-button>
        <va-button
          icon="add"
          color="info"
          @click="getLastEcritureForm()"
        >
          Reprendre les données de la dernière écriture
        </va-button>
        <va-button
          icon="delete"
          color="danger"
          @click="removeEcriture(lastID)"
        >
          Supprimer la dernière écriture
        </va-button>
        <va-pagination
          class="journalButtonPagination"
          v-model="currentPage"
          input
          :pages="pages"
        />
      </div>
    </div>
  </div>
  <va-modal
    v-model="showChoosingFileModal"
    fullscreen
    maxWidth="100%"
    fixedLayout
    no-padding
    :ok-text="modalFileSelected.id === undefined ? 'Annuler' : 'Choisir'"
    cancelText="Annuler"
    @cancel="modalFileSelected = {} as Fichier"
  >
    <template #header>
      <h3>Fichiers</h3>
    </template>
    <va-inner-loading loadingFiles>
      <div class="filesModalGrid">
        <va-card
          v-for="file in standaloneFiles"
          :key="file.id"
          @click="modalFileSelected = file"
          :stripe="modalFileSelected.id === file.id"
        >
          <vue-pdf-embed
            v-if="file.mime === 'application/pdf'"
            :source="file.data"
          />
          <va-image
            class="w-full md:w-1/2 lg:w-1/3"
            :src="file.data"
            v-else
          />
          <va-card-title>{{ file.createdAt.toISOString().split('T')[0] }} - {{ file.nom }}</va-card-title>
        </va-card>
      </div>
    </va-inner-loading>
  </va-modal>
  <va-modal
    v-model="showEcritureModal"
    fullscreen
    maxWidth="100%"
    fixedLayout
    no-padding
    ok-text='Ok'
    cancelText="Annuler"
  >
    <template #header>
      <h3>
        Ecriture
      </h3>
    </template>
    <va-inner-loading loadingEcriture>
      <div class="ecritureModalGrid" v-if="showEcritureHistory !== undefined && showEcritureHistory.ecriture !== undefined">
        <va-card v-if="showEcritureHistory.ecriture.fichier !== undefined">
          <va-card-title>Fichier</va-card-title>
          <vue-pdf-embed
            v-if="showEcritureHistory.ecriture.fichier.mime === 'application/pdf'"
            :source="showEcritureHistory.ecriture.fichier.data"
          />
          <va-image
            class="w-full md:w-1/2 lg:w-1/3"
            :src="showEcritureHistory.ecriture.fichier.data"
            v-else
          />
          <!-- TODO If an ecriture is link to file
        -> display ecriture -->
        </va-card>
        <EcritureView
          :ecriture="showEcritureHistory.ecriture" />
        <EcritureView
          v-if="showEcritureHistory.ecritureTVA !== undefined"
          :ecriture="showEcritureHistory.ecritureTVA"
          :tauxTVA="showEcritureHistory.tauxTVA" />
        <div class="modalHistoryColumn">
          <EcritureView
            v-for="historyEcriture in showEcritureHistory.history"
            v-bind:key="historyEcriture.ID"
            :ecriture="historyEcriture"
            class="modalHistoryCard" />
        </div>
      </div>
    </va-inner-loading>
  </va-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import { VaFile, useForm } from 'vuestic-ui';
import VuePdfEmbed from 'vue-pdf-embed';
import EcritureView from './EcritureView.vue';
// eslint-disable-next-line import/no-unresolved, import/extensions
import Compte from '@/types/Compte';
// eslint-disable-next-line import/no-unresolved, import/extensions
import Ecriture from '@/types/Ecriture';
// eslint-disable-next-line import/no-unresolved, import/extensions
import EcritureTVA from '@/types/EcritureTVA';
import {
  getEcritureTVAById, editEcritureTVAById, getStandaloneFichiers, getHistoryEcriture,
// eslint-disable-next-line import/no-unresolved, import/extensions
} from '@/api/api';
import {
  dateInExerciceComptableRange, roundTwoDecimal, readFile,
// eslint-disable-next-line import/no-unresolved, import/extensions
} from '@/components/Utils';
// eslint-disable-next-line import/no-unresolved, import/extensions
import TypeFichier from '@/types/TypeFichier';
// eslint-disable-next-line import/no-unresolved, import/extensions
import ExerciceComptable from '@/types/ExerciceComptable';
// eslint-disable-next-line import/no-unresolved, import/extensions
import Fichier from '@/types/Fichier';
// eslint-disable-next-line import/no-unresolved, import/extensions
import History from '@/types/History';

export default defineComponent({
  name: 'JournalList',
  data() {
    const columns = [
      // { key: 'image', sortable: true },
      { key: 'ID', sortable: true, sortingFn: (a: any, b: any) => Number(a) - Number(b) },
      { key: 'date', sortable: true, sortingFn: (a: any, b: any) => Number(a) - Number(b) },
      { key: 'debit.id', name: 'debit', sortable: true },
      { key: 'credit.id', name: 'credit', sortable: true },
      { key: 'libelle', sortable: false },
      { key: 'montant', sortable: true },
    ];

    const monetaryFormat = new Intl.NumberFormat('fr-CH', {
      style: 'currency',
      currency: 'CHF',

      // These options are needed to round to whole numbers if that's what you want.
      // minimumFractionDigits: 0, // (this suffices for whole numbers
      // , but will print 2500.10 as $2,500.1)
      // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const timeFormat = new Intl.DateTimeFormat('fr-CH', {
      timeStyle: 'medium',
      dateStyle: 'short',
    });

    const store = useStore();

    const {
      isValid, validate, reset, resetValidation,
    } = useForm('formEcritureTVA');

    return {
      columns,
      form: {
        ecriture: {} as Ecriture,
        montantTVA: 0.0,
        montantHorsTVA: 0.0,
      },
      lastFormData: {
        ecriture: {} as Ecriture,
        montantTVA: 0.0,
        montantHorsTVA: 0.0,
      },
      customFilter: {
        compte: '' as unknown as Compte,
        from: new Date(),
        to: new Date(),
        libelle: '',
      },
      showEcritureForm: false,
      accordions: [],
      editMode: false,
      editedEcriture: {} as Ecriture | EcritureTVA,
      perPage: 100,
      currentPage: 1,
      loading: false,
      pendingFile: {} as VaFile,
      pendingFileType: {} as TypeFichier,
      showChoosingFileModal: false,
      modalFileSelected: {} as Fichier,
      standaloneFiles: new Array<Fichier>(),
      showEcritureModal: false,
      showEcritureHistory: {} as History,
      timeFormat,
      loadingFiles: false,
      loadingEcriture: false,
      TVA: this.initializeTVA(),
      filter: '',
      filtered: Array<any>(),
      monetaryFormat,
      dateInExerciceComptableRange,
      roundTwoDecimal,
      readFile,
      store,
      formTVA: {
        isValid,
        validate,
        reset,
        resetValidation,
      },
    };
  },
  components: {
    VuePdfEmbed,
    EcritureView,
  },
  computed: {
    comptes(): Compte[] {
      return this.$store.getters.comptes;
    },
    compteSelect(): Compte[] {
      return this.comptes.filter((compte:Compte) => String(compte.id).length === 4);
    },
    filterSum(): any {
      if (this.customFilter.compte.id !== null && this.customFilter.compte.id !== undefined) {
        return this.filterEvent();
      }
      return null;
    },
    isUserAdmin(): boolean {
      return this.$store.getters.user.admin === true;
    },
    vuexMessage() {
      return this.$store.getters.message;
    },
    comptabiliteID() {
      return this.$route.params.comptabilite;
    },
    /**
      return the biggest ID in ecritures
    */
    lastID(): number {
      const IDs = this.$store.getters.ecritures.map((e:Ecriture) => e.ID);
      return Math.max(...IDs);
    },
    /**
     * minimum date of the store ecriture array
     */
    minDate(): Date {
      if (this.$store.getters.ecritures.length === 0) {
        return new Date();
      }
      const dates = this.$store.getters.ecritures.map((e:Ecriture) => e.date.getTime());
      return new Date(Math.min(...dates));
    },
    /**
     * maximum date of the store ecriture array
     */
    maxDate() : Date {
      if (this.$store.getters.ecritures.length === 0) {
        return new Date();
      }
      const dates = this.$store.getters.ecritures.map((e:Ecriture) => e.date.getTime());
      return new Date(Math.max(...dates));
    },
    comptabilite() {
      console.log('Selected Comptabilite', this.$store.getters.getSelectedComptabilite);
      return this.$store.getters.getSelectedComptabilite;
    },
    exercice() {
      return this.$route.params.exercice;
    },
    ecritures(): Ecriture[] {
      return this.$store.getters.ecritures.filter(
        (ecriture:Ecriture) => (this.customFilter.compte.id === null
           || this.customFilter.compte.id === undefined
           || this.customFilter.compte.id === ecriture.debit.id
           || this.customFilter.compte.id === ecriture.credit.id)
           && this.customFilter.from <= ecriture.date
           && this.customFilter.to >= ecriture.date
           && (this.customFilter.libelle.length === 0
            || ecriture.libelle.includes(this.customFilter.libelle)),
      );
    },
    pages():number {
      return (this.perPage && this.perPage !== 0)
        ? Math.ceil(this.filtered.length / this.perPage)
        : this.filtered.length;
    },
    editedIds(): number[] {
      if (this.editedEcriture !== undefined
          && this.editedEcriture !== null) {
        if ((this.editedEcriture as EcritureTVA).tauxTVA === undefined) {
          return [(this.editedEcriture as Ecriture).ID];
        }
        return [
          (this.editedEcriture as EcritureTVA).ecriture.ID,
          (this.editedEcriture as EcritureTVA).ecritureTVA.ID,
        ];
      }
      return [];
    },
    typesFilesOptions(): TypeFichier[] {
      return this.store.getters.typesFichier;
    },
    exerciceComptable(): ExerciceComptable {
      return this.store.getters.getSelectedExerciceComptable;
    },
  },
  mounted() {
    this.getRequests();
  },
  watch: {
    'TVA.toggle': function (newVal, oldVal) {
      this.TauxTVAChange();
    },
    minDate() {
      this.customFilter.from = this.minDate;
    },
    maxDate() {
      this.customFilter.to = this.maxDate;
    },
    showChoosingFileModal() {
      if (this.showChoosingFileModal) {
        this.loadingFiles = true;
        getStandaloneFichiers().then((response) => {
          if (response !== undefined) {
            this.standaloneFiles = response;
          }
        }).finally(() => {
          this.loadingFiles = false;
        });
      }
    },
  },
  methods: {
    initializeTVA() {
      const exercice:ExerciceComptable = this.$store.getters.getSelectedExerciceComptable;
      console.log('Exercice TVA', exercice);
      return {
        Taux: exercice.VAT,
        TauxReduit: exercice.VATReduced,
        Vente: 2200,
        Achat: 1170,
        Frais: 1171,
        toggleValue: [
          { text: 'Tva Normal', value: exercice.VAT },
          { text: 'Tva Réduit', value: exercice.VATReduced },
          { text: 'Pas de TVA', value: 0 },
          { text: '100%', value: 100 },
        ],
        toggle: exercice.VAT,
      };
    },
    getRequests() {
      this.store.dispatch('updateAllTypesFichier');
      this.store.dispatch('updateAllComptes').then(
        () => this.store.dispatch('updateAllEcritures'),
      );
    },
    addEcritureForm() {
      this.showEcritureForm = true;
      this.editedEcriture = {} as Ecriture;
      this.editMode = false;
    },
    getLastEcritureForm() {
      this.addEcritureForm();
      this.copyLastFormToForm();
    },
    hideEcritureForm() {
      this.showEcritureForm = false;
      this.editedEcriture = {} as Ecriture;
      this.editMode = false;

      this.form.ecriture = {} as Ecriture;
      this.form.montantTVA = 0.0;
      this.form.montantHorsTVA = 0.0;

      this.pendingFile = {} as VaFile;
      this.pendingFileType = {} as TypeFichier;
    },
    addEcriture() {
      /* if (!validate()) {
        // TODO when i know how to have 2 validate
        return;
      } */
      if (this.form.ecriture.debit.id === this.form.ecriture.credit.id) {
        this.store.commit(
          'addMessage',
          {
            message: 'Débit ne peux pas être égal à crédit',
            type: 'warning',
          },
        );
        return;
      }
      if (this.pendingFile && Object.keys(this.pendingFileType).length === 0) {
        this.store.commit(
          'addMessage',
          {
            message: 'Type Fichier manquant',
            type: 'warning',
          },
        );
        return;
      }
      this.copyFormToLastForm();

      if (this.pendingFile) {
        this.readFile(this.pendingFile)
          .then((data:any) => {
            const fichier = {} as Fichier;
            fichier.data = data;
            // eslint-disable-next-line prefer-destructuring
            fichier.mime = data.split(';')[0].split(':')[1];
            fichier.type = this.pendingFileType;
            fichier.nom = `Fichier: ${this.form.ecriture.libelle}`;

            if (this.editMode) {
              this.$store.dispatch('editEcriture', {
                ecriture: this.form.ecriture,
                fichier,
              })
                .then(() => {
                  this.showEcritureForm = false;
                  this.editMode = false;
                  this.editedEcriture = {} as Ecriture;

                  this.form.ecriture = {} as Ecriture;

                  this.pendingFile = {} as VaFile;
                  this.pendingFileType = {} as TypeFichier;
                });
            } else {
              this.$store.dispatch('addEcriture', {
                ecriture: this.form.ecriture,
                fichier,
              })
                .then(() => {
                  this.showEcritureForm = false;
                  this.editedEcriture = {} as Ecriture;

                  this.form.ecriture = {} as Ecriture;

                  this.pendingFile = {} as VaFile;
                  this.pendingFileType = {} as TypeFichier;
                });
            }
          });
      } else if (this.editMode) {
        this.$store.dispatch('editEcriture', {
          ecriture: this.form.ecriture,
          fichier: this.modalFileSelected.id !== undefined ? this.modalFileSelected : undefined,
        })
          .then(() => {
            this.showEcritureForm = false;
            this.editMode = false;
            this.editedEcriture = {} as Ecriture;

            this.form.ecriture = {} as Ecriture;

            this.pendingFile = {} as VaFile;
            this.pendingFileType = {} as TypeFichier;
          });
      } else {
        this.$store.dispatch('addEcriture', {
          ecriture: this.form.ecriture,
          fichier: this.modalFileSelected.id !== undefined ? this.modalFileSelected : undefined,
        })
          .then(() => {
            this.showEcritureForm = false;
            this.editedEcriture = {} as Ecriture;

            this.form.ecriture = {} as Ecriture;

            this.pendingFile = {} as VaFile;
            this.pendingFileType = {} as TypeFichier;
          });
      }
    },
    addEcritureTVA() {
      if (!this.formTVA.validate()) {
        return;
      }
      if (this.form.ecriture.debit.id === this.form.ecriture.credit.id) {
        this.store.commit(
          'addMessage',
          {
            message: 'Débit ne peux pas être égal à crédit',
            type: 'warning',
          },
        );
        return;
      }
      if (this.pendingFile && Object.keys(this.pendingFileType).length === 0) {
        this.store.commit(
          'addMessage',
          {
            message: 'Type Fichier manquant',
            type: 'warning',
          },
        );
        return;
      }

      this.copyFormToLastForm();

      if (this.pendingFile) {
        this.readFile(this.pendingFile)
          .then((data:any) => {
            const fichier = {} as Fichier;
            fichier.data = data;
            // eslint-disable-next-line prefer-destructuring
            fichier.mime = data.split(';')[0].split(':')[1];
            fichier.type = this.pendingFileType;
            fichier.nom = `Fichier: ${this.form.ecriture.libelle}`;

            if (this.editMode) {
              editEcritureTVAById(
                this.form.ecriture.ID,
                this.form.ecriture,
                this.form.montantTVA,
                this.TVA.toggle,
                fichier,
              )
                .then((response) => {
                  if (response) {
                    this.showEcritureForm = false;
                    this.editedEcriture = {} as Ecriture;
                    this.editMode = false;

                    this.form.ecriture = {} as Ecriture;
                    this.form.montantTVA = 0;
                    this.form.montantHorsTVA = 0;

                    this.pendingFile = {} as VaFile;
                    this.pendingFileType = {} as TypeFichier;
                  } else {
                    // error handling ?
                  }
                  this.$store.dispatch('updateAllEcritures');
                });
            } else {
              this.$store.dispatch(
                'addEcritureTVA',
                {
                  ecriture: this.form.ecriture,
                  montantTVA: this.form.montantTVA,
                  tauxTVA: this.TVA.toggle,
                  fichier,
                },
              )
                .then(() => {
                  this.showEcritureForm = false;
                  this.editedEcriture = {} as Ecriture;

                  this.form.ecriture = {} as Ecriture;
                  this.form.montantTVA = 0;
                  this.form.montantHorsTVA = 0;

                  this.pendingFile = {} as VaFile;
                  this.pendingFileType = {} as TypeFichier;
                });
            }
          });
      } else if (this.editMode) {
        editEcritureTVAById(
          this.form.ecriture.ID,
          this.form.ecriture,
          this.form.montantTVA,
          this.TVA.toggle,
          this.modalFileSelected.id !== undefined ? this.modalFileSelected : undefined,
        )
          .then((response) => {
            if (response) {
              this.showEcritureForm = false;
              this.editedEcriture = {} as Ecriture;
              this.editMode = false;

              this.form.ecriture = {} as Ecriture;
              this.form.montantTVA = 0;
              this.form.montantHorsTVA = 0;

              this.pendingFile = {} as VaFile;
              this.pendingFileType = {} as TypeFichier;
            } else {
              // error handling ?
            }
            this.$store.dispatch('updateAllEcritures');
          });
      } else {
        this.$store.dispatch(
          'addEcritureTVA',
          {
            ecriture: this.form.ecriture,
            montantTVA: this.form.montantTVA,
            tauxTVA: this.TVA.toggle,
            fichier: this.modalFileSelected.id !== undefined ? this.modalFileSelected : undefined,
          },
        )
          .then(() => {
            this.showEcritureForm = false;
            this.editedEcriture = {} as Ecriture;

            this.form.ecriture = {} as Ecriture;
            this.form.montantTVA = 0;
            this.form.montantHorsTVA = 0;

            this.pendingFile = {} as VaFile;
            this.pendingFileType = {} as TypeFichier;
          });
      }
    },
    /**
     * Copy data from form to the last form
     */
    copyFormToLastForm() {
      this.lastFormData.ecriture = this.form.ecriture;
      this.lastFormData.montantTVA = this.form.montantTVA;
      this.lastFormData.montantHorsTVA = this.form.montantHorsTVA;
    },
    /**
     * Copy data from last form to the form
     */
    copyLastFormToForm() {
      this.form.ecriture = this.lastFormData.ecriture;
      this.form.montantTVA = this.lastFormData.montantTVA;
      this.form.montantHorsTVA = this.lastFormData.montantHorsTVA;
    },
    showEcriture(id:number) {
      // show ecriture in a modal with file visualisation
      this.showEcritureModal = true;

      this.loadingEcriture = true;
      // get ecriture with files and history
      getHistoryEcriture(id).then((response) => {
        if (response !== undefined) {
          this.showEcritureHistory = response;
        }
      }).finally(() => {
        this.loadingEcriture = true;
      });
      // history
    },
    editEcriture(id:number) {
      this.editedEcriture = this.$store.getters.getEcritureById(id);
      this.showEcritureForm = true;
      this.editMode = true;

      // check wheter this is an ecriture or a tva ecriture
      getEcritureTVAById(id).then((response) => {
        if (response === undefined) {
          // id is not linked to ecriture TVA, so we can do a 'simple edit'
          // WITHOUT TVA (i.e 0%)
          // So editedEcriture stay an Ecriture
        } else {
          console.log(response);
          // id is part of a tva ecriture
          // so we have all information to edit ecriture
          // editedEcriture become a TVA ecriture
          this.editedEcriture = response;
        }

        if (this.editedEcriture !== undefined
          && this.editedEcriture !== null) {
        // check wheter edited Ecriture is an Ecriture or Ecriture TVA
          if ((this.editedEcriture as EcritureTVA).tauxTVA === undefined) {
          // copy data to form
            this.form.ecriture = this.editedEcriture as Ecriture;
            this.TVA.toggle = 0;
          } else {
            this.form.ecriture = (this.editedEcriture as EcritureTVA).ecriture;
            this.form.montantTVA = Number(
              (this.editedEcriture as EcritureTVA).ecritureTVA.montant,
            );
            this.form.montantHorsTVA = this.roundTwoDecimal(
              Number(this.form.ecriture.montant) - this.form.montantTVA,
            );

            this.TVA.toggle = (this.editedEcriture as EcritureTVA).tauxTVA;
          }
        }
      })
        .catch((error) => {
          console.log(error);
        });
    },
    removeEcriture(id:number) {
      if (this.comptabilite.TVA === '1') {
        this.$store.dispatch('removeEcritureTVA', {
          id,
        });
      } else {
        this.$store.dispatch('removeEcriture', {
          id,
        });
      }
    },
    customFilteringFn(source: Compte) {
      if (this.customFilter.compte === null || this.customFilter.compte === undefined) {
        return true;
      }

      return this.customFilter.compte === source;
    },
    filterEvent(): number {
      const reducer = (
        previousValue: number,
        currentValue: Ecriture,
      ) => {
        const montant = Number(currentValue.montant);
        if (String(this.customFilter.compte.id).startsWith('2')
          || String(this.customFilter.compte.id).startsWith('3')) {
          // if the account is a passif (2xxx) or a product (3xxx)
          // the addition method is inverted
          if (currentValue.debit.id === this.customFilter.compte.id) {
            return Number((previousValue - montant).toFixed(2));
          }
          return Number((previousValue + montant).toFixed(2));
        }
        if (currentValue.debit.id === this.customFilter.compte.id) {
          return Number((previousValue + montant).toFixed(2));
        }
        return Number((previousValue - montant).toFixed(2));
      };

      return this.ecritures.reduce(reducer, 0.0);
    },
    clearFilter() {
      this.customFilter.compte = {} as Compte;
      // TODO call reset method on select
      console.log(`new range value start ${this.minDate} end ${this.maxDate}`);
      this.customFilter.from = this.minDate;
      this.customFilter.to = this.maxDate;
      this.customFilter.libelle = '';
      this.$forceUpdate();
    },
    /**
     * Compute montant and tva montant based on montantTot
     */
    TVAChange() {
      // Si montant Hors TVA != 0 && montant & montant TVA = 0
      // Calcul les deux autres montant
      if (this.form.montantHorsTVA !== 0
          && (this.form.ecriture.montant === 0 || this.form.ecriture.montant === undefined)
          && this.form.montantTVA === 0) {
        if (this.TVA.toggle === 100) {
          this.form.montantHorsTVA = 0;
          this.form.montantTVA = this.form.ecriture.montant;
        } else {
          // eslint-disable-next-line max-len
          this.form.ecriture.montant = this.roundTwoDecimal(
            this.form.montantHorsTVA * (1 + (this.TVA.toggle / 100)),
          );
          this.form.montantTVA = this.roundTwoDecimal(
            this.form.ecriture.montant - this.form.montantHorsTVA,
          );
        }
      // eslint-disable-next-line max-len
      } else if (this.form.montantHorsTVA === 0
        && this.form.ecriture.montant !== 0
        && this.form.ecriture.montant !== undefined
        && this.form.montantTVA === 0) {
        this.form.montantHorsTVA = this.roundTwoDecimal(
          this.form.ecriture.montant / (1 + (this.TVA.toggle / 100)),
        );
        this.form.montantTVA = this.roundTwoDecimal(
          this.form.ecriture.montant - this.form.montantHorsTVA,
        );
        /* this.form.montantTVA = this.roundTwoDecimal(
          this.form.ecriture.montant * (this.TVA.toggle / 100),
        );
        this.form.montantHorsTVA = this.roundTwoDecimal(
          this.form.ecriture.montant - 0 + this.form.montantTVA,
        ); */
      // eslint-disable-next-line max-len
      } else if (this.form.montantHorsTVA === 0
        && (this.form.ecriture.montant === 0 || this.form.ecriture.montant === undefined)
        && this.form.montantTVA !== 0) {
        // eslint-disable-next-line max-len
        this.form.montantHorsTVA = this.roundTwoDecimal(
          this.form.montantTVA / (this.TVA.toggle / 100),
        );
        this.form.ecriture.montant = this.roundTwoDecimal(
          this.form.montantTVA - 0 + this.form.montantHorsTVA,
        );
      } if (this.TVA.toggle === 100) {
        this.form.montantHorsTVA = 0;
        this.form.montantTVA = this.form.ecriture.montant;
      } else {
        // all amount are already written (with old value for example)
        // we check that everything is right based on montant
        this.form.montantHorsTVA = this.roundTwoDecimal(
          this.form.ecriture.montant / (1 + (this.TVA.toggle / 100)),
        );
        this.form.montantTVA = this.roundTwoDecimal(
          this.form.ecriture.montant - this.form.montantHorsTVA,
        );
      }
    },
    TauxTVAChange() {
      if (this.form.ecriture.montant !== 0) {
        if (this.TVA.toggle === 100) {
          this.form.montantHorsTVA = 0;
          this.form.montantTVA = this.form.ecriture.montant;
        } else {
          this.form.montantHorsTVA = this.roundTwoDecimal(
            this.form.ecriture.montant / (1 + (this.TVA.toggle / 100)),
          );
          this.form.montantTVA = this.roundTwoDecimal(
            this.form.ecriture.montant - this.form.montantHorsTVA,
          );
          // eslint-disable-next-line max-len
          /* this.form.ecriture.montant = this.roundTwoDecimal(
            this.form.montantHorsTVA / (1 + (this.TVA.toggle / 100)));
          this.form.montantTVA = this.roundTwoDecimal(
            this.form.montantHorsTVA - this.form.ecriture.montant,
          ); */
        }
      }
    },
    // eslint-disable-next-line consistent-return
    getEditedRowBind(row: any) {
      if (this.editedIds.includes(row.ID)) {
        return {
          class: ['journal-edited-row'],
        };
      }
    },
    /**
     * check if given date is whithin ecriture date range
     * @param date to check
     * @return true if in range
     */
    dateInEcritureDateRange(date: Date) : boolean {
      const min = new Date(this.minDate);
      const max = new Date(this.maxDate);

      min.setDate(min.getDate() - 1);
      max.setDate(max.getDate() + 1);

      return date >= min && date <= max;
    },
    highlightText(text: string, search: string) : string {
      if (search.length === 0) {
        return text;
      }

      return text.replace(new RegExp(search, 'gi'), (match) => `<mark>${match}</mark>`);
    },
    /**
     * Check whether ecriture Id has a file linked to it
     * @param id
     */
    hasFichier(id:number): boolean {
      return this.store.getters.getEcritureById(id).fichier !== undefined;
    },
    displayEcritureAsTable(ecriture:Ecriture) : string {
      if (ecriture === undefined) {
        return 'no ecriture to be shown';
      }
      return `<table class="va-table">
              <tr>
                <th>ID</th>
                <td> ${ecriture.ID}</td>
              </tr>
              <tr>
                <th>Date</th>
                <td>${ecriture.date.toISOString().split('T')[0]}</td>
              </tr>
              <tr>
                <th>Debit</th>
                <td>${ecriture.debit.id}
                  - ${ecriture.debit.libelle}</td>
              </tr>
              <tr>
                <th>Credit</th>
                <td>${ecriture.credit.id}
                  - ${ecriture.credit.libelle}</td>
              </tr>
              <tr>
                <th>Libelle</th>
                <td>${ecriture.libelle}</td>
              </tr>
              <tr>
                <th>Montant</th>
                <td>${this.monetaryFormat.format(ecriture.montant)}</td>
              </tr>
              <tr>
                <th>Créé le / par</th>
                <td>${this.timeFormat.format(ecriture.createdAt)} /
                  ${ecriture.updatedBy?.username}</td>
              </tr>
            </table>`;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.journalList {
    height: calc(100vh - var(--titleHeight));
    overflow-y: scroll;
    overflow: hidden;
}

.list-items {
    text-align: left;
}

#ecritureForm {
    /*width: 300px;*/
}

.journalFilter{
  background-color: var(--va-primary);
  color: var(--va-text-inverted);
  padding: 5px;
}

.journalAffixFilter {
  height:75px;
}

.journalForm{
  background-color:rgb(220, 220, 220);
  padding: 20px;
}

.jounalFormEdit {
  background-color:rgb(252, 204, 204);
  padding: 20px;
}

.journalTable {
  height: calc(100vh - var(--titleHeight) - 75px);
  max-height: calc(100vh - var(--titleHeight) - 75px);
}

.filterElement {
  margin-left: 20px;
  margin-right: 20px;
  color: var(--va-text-primary);
  max-width: 300px;
}

.filterRow{
  margin-top: 10px;
}

.filterSum {
  vertical-align: middle;
}

.formInput {
  margin: 20px;
}

.journalButton {
  display: grid;
  column-gap: 10px;
  margin: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
}

.tablePagination {
    display: flex;
    justify-content: center;
  }

.journalButtonPagination {
  background-color: white;
}

.box {
  display: flex;
  flex-flow: column;
}

.box .header {
  flex: 0 1 auto;
  background-color: var(--va-primary);
  color: var(--va-text-inverted);
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .content {
  flex: 1 1 auto;
}

.box .footer {
  flex: 0 1 auto;
  background-color:rgb(220, 220, 220);
}

.journalGrid {
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
}

.journalGridTVA {
  grid-column-start: 1;
}

.journalFilterGrid {
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
}

.filesModalGrid {
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
}

.ecritureModalGrid{
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
}

.refreshButton {
  position: absolute;
  right: 5px;
  top: 30px;
}

.clearFilterButton {
  position: absolute;
  right: 30px;
  top: 30px;
}

.modalHistoryCard {
  margin-bottom: 5px;
}
</style>
